import React from "react";
import Save from "./Save";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import SectionTitle from "./SectionTitle";
import ConfirmBox from "./ConfirmBox";
import ErrorBox from "./ErrorBox";
import { variables, CompanyLoginPath, popupcss } from "../Variables";
import TextInput from "./TextInput";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { urlCompanyName } from "../features/util/commonFun";
import { appInsights } from "../features/util/ApplicationInsight";

const axios = require('axios');
var hash = require('object-hash');

class CategoryInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            category: this.props.Category || { id: -1, name: "" },
            dialogBox: null,
            currentCategory: this.props.Category,
            isRedirectToCompanyLogin: false
        };
        this.handleSaveClick = this.handleSaveClick.bind(this);
        this.handleNamechange = this.handleNamechange.bind(this);
        this.registerOrUpdateCategory = this.registerOrUpdateCategory.bind(this);
        this.deleteDialog = this.deleteDialog.bind(this);
        this.complete = this.complete.bind(this);
        this.redirect = this.redirect.bind(this);
    }

    isDataChange() {
        if (hash(this.state.category) !== hash(this.state.currentCategory)) {
            return true;
        } else {
            return false;
        }
    }

    // 保存ボタン処理
    // カテゴリ名が登録済みか確認して、未存在の場合、登録確認メッセージを出す。
    // 存在の場合、エラーメッセージを出す。
    handleSaveClick(e) {
        if (this.state.category.name.length <= 0) {
            return;
        }
        //重複確認 
        let paramObj = { code: this.state.category.id, name: this.state.category.name };
        axios.get(variables.CATEGORY_URL + '/checkduplicate', {
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        })
            .then((result) => {
                // カテゴリ名がDBに未存在の場合、登録確認ボックスを表示する。
                if (result.data === false) {
                    this.setState({
                        dialogBox: (
                            <ConfirmBox
                                className="absolute right-[162px] top-[265px] w-[471px]"
                                title="入力内容の登録" message="登録しますか？"
                                onYesClick={this.registerOrUpdateCategory}
                                onNoClick={this.deleteDialog} />
                        ),
                    });

                } else {
                    // エラーボックスを表示する。
                    this.setState({
                        dialogBox: (
                            <ErrorBox className="absolute right-[162px] top-[265px] w-[471px]"
                                Title="入力内容の登録" Message="既に登録済みです。"
                                onYesClick={this.deleteDialog} />
                        ),
                    });
                }
            }).catch((error) => {
                // ユーザー権限エラーの場合、ログイン画面へ移動する。
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。
                    this.redirect();
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                    appInsights.trackException({ ...error, errorFunction: "categoryinput.handleSaveClick()" });
                }
            });
    }

    //　カテゴリを登録もしくは更新する
    registerOrUpdateCategory() {
        // 新規登録の場合、PUT
        //　編集の場合、POST
        let method = 'PUT';
        let body = { categoryCode: this.state.category.id, categoryName: this.state.category.name };
        if (this.state.category.id === -1) {
            method = 'POST';
            body = { categoryName: this.state.category.name }
        }

        axios({
            method: method,
            url: variables.CATEGORY_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true,
            data: body
        }).then((result) => {
            this.complete();
        }).catch((error) => {
            if (error.response.status === 401) {
                // ログイン画面へ移動する。
                this.redirect();
            }
            else {
                appInsights.trackTrace({ data: JSON.stringify(body) });
                appInsights.trackException({ ...error, errorFunction: "categoryinput.registerOrUpdateCategory()" });
            }
        });
    }

    // 確認ダイアログ、エラーダイアログなどを削除する。
    deleteDialog() {
        this.setState({
            dialogBox: null,
        });
    }

    complete() {
        this.props.onComplete();
    }

    handleNamechange(e) {
        this.setState((state) => {
            return {
                category: {
                    ...state.category,
                    name: e.target.value
                },
            };
        });
    }

    redirect() {
        this.setState({
            isRedirectToCompanyLogin: true
        });
    }

    render() {
        if (this.state.isRedirectToCompanyLogin) {
            return (
                <Navigate to={generatePath(CompanyLoginPath, {
                    company: urlCompanyName
                })} />
            );
        }
        return (
            <div className={popupcss}>
                <div className="h-[1033px] ml-[1200px] w-[720px] mt-[47px] bg-white border-l-2 shadow-md px-[15px] pt-[30px]">
                    <div className="grid grid-cols-2">
                        {this.state.category.id !== -1 ? (
                            <SectionTitle Text="カテゴリの編集" />
                        ) : (
                            <SectionTitle Text="カテゴリの追加" />
                        )}
                        <div className="justify-self-end">
                            <button onClick={this.props.onClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <Save onClick={this.handleSaveClick}
                            isEnable={this.state.category.name.length > 0 && this.isDataChange() === true} />

                    </div>
                    <div className="h-[2px] bg-[#C8C6C4] opacity-[.10] mx-[-15px]" />
                    <div className="my-[15px]">
                        <SectionTitle Text="基本情報" />
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="w-[135px]">
                            <label htmlFor="cateName">カテゴリ名</label>
                        </div>
                        <div className="text-[#CD0000] mx-3"><strong>*</strong></div>
                        <TextInput id="cateName" value={this.state.category.name}
                            maxLength={50}
                            onChange={this.handleNamechange} />
                    </div>
                    <div>
                        {this.state.dialogBox}
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoryInput;