import { React, useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { IconContext } from "react-icons";

function Collapsible(props) {
    let desiredScrollIndex = props.desiredScrollIndex;
    const [isOpen, setIsOpen] = useState(props.isOpen);
    var div = document.getElementById("scrollDiv");

    useEffect(() => {
        if (desiredScrollIndex > -1) {
            var scrollPosition = desiredScrollIndex * 53;
            div.scrollTop = scrollPosition;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desiredScrollIndex])

    function onchange() {
        let value = !isOpen;
        setIsOpen(value);
        props.onChange(value);
    }

    return (
        <div>
            <div className={"p-3 w-fit h-[53px] border-bottom d-flex justify-content-between " + props.margin}>
                <button type="button" className="btn" onClick={onchange}>
                    <IconContext.Provider value={{ color: "black", size: "16px", className: "inline-flex ml-[42px]" }}>
                        {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
                    </IconContext.Provider>
                    <h6 className="font-weight-bold inline-flex pl-[13px] w-[1530px]">{props.title}</h6>
                </button>
            </div>
            {isOpen ? (
                <div>
                    {props.children}
                </div>
            ) : null}
        </div>
    );
}
Collapsible.defaultProps = {
    isOpen: false,
    onChange: () => void (0),
}
export default Collapsible;