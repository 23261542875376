import { React, useEffect } from "react"
import AppTitleBar from "../components/AppTitleBar";
import SectionTitle from "../components/SectionTitle";
import { variables, StatusCode, MasterPage } from "../Variables";
import { generatePath, useLocation } from 'react-router';
import { NavigateToCompanyLogin, NavigateToUserLogin, urlCompanyName } from "../features/util/commonFun";
import { useState } from "react";
import { useNavigate } from "react-router";
import ProcessChart from "../features/processChart/ProcessChart";
import Category from "../features/category/Category";
import Item from "../features/item/Item";
import User from "../features/user/User";
import Logout from "../components/Logout";
import CompletedRecord from "../features/completedRecord/CompletedRecord";
import WorkRestore from "../features/workRestore/WorkRestore";
import PageLoading from "../components/PageLoading";
import { appInsights } from "../features/util/ApplicationInsight";
import { ProductionPlan } from "../features/productionPlan/ProductionPlan";

const axios = require('axios');

function MasterLayout() {
    const location = useLocation();
    const redirectPage = location.state ? location.state.id : 0;
    const baseLinkCss = "h-[53px] flex items-center px-[15px] hover:cursor-pointer " + variables.HOVER_CSS;
    const navigate = useNavigate();
    const baseCss = baseLinkCss;
    const selectedCss = baseLinkCss + " bg-[#D8D7D5]";
    const [showLogout, setShowLogout] = useState(false);

    useEffect(() => {
        const redirect = () => {
            NavigateToCompanyLogin(navigate);
        }
        const redirectUser = () => {
            NavigateToUserLogin(navigate);
        }

        axios({
            method: 'GET',
            url: variables.LOGIN_URL + "/validate",
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let statusCd = response.data.statusCode;
            if (statusCd !== StatusCode.Authenticated && statusCd !== StatusCode.AuthorizedWithAdministrator) {
                redirect();
            }
            else if (statusCd === StatusCode.Authenticated) {
                redirectUser();
            }
        })
    }, [])

    function handleActiveLink(id) {
        if (id === redirectPage) {
            return;
        }
        navigateTo(id);
    }

    function navigateTo(targetPage) {
        if (targetPage === MasterPage.HOME) {
            navigatetoHome();
        }
        if (targetPage === MasterPage.PROCESS_CHART) {
            navigatetoProcessChart();
        }
        if (targetPage === MasterPage.CATEGORY) {
            navigatetoCategory();
        }
        if (targetPage === MasterPage.ITEM) {
            navigatetoItem();
        }
        if (targetPage === MasterPage.USER) {
            navigatetoUser();
        }
        if (targetPage === MasterPage.COMPLETEDRECORD) {
            navigatetoRecord();
        }
        if (targetPage === MasterPage.WORKRESTORE) {
            navigatetoWorkRestore();
        }
        if (targetPage === MasterPage.PRODUCTION_PLAN) {
            navigatetoProductionPlan();
        }
        // 工程設定以外のマスターを選択する場合、工程設定の展開保持をクリアする
        if (targetPage !== MasterPage.HOME && targetPage !== MasterPage.PROCESS_CHART) {
            sessionStorage.removeItem('categoryCodeList');
            sessionStorage.removeItem('itemCodeList');
            sessionStorage.removeItem('categoryFilterText');
            sessionStorage.removeItem('itemCodeFilterText');
            sessionStorage.removeItem('itemNameFilterText');
            sessionStorage.removeItem('isDisplayTempSaveOnly');
        }
    }

    function onAppNameClick() {
        axios({
            method: 'GET',
            url: variables.LOGIN_URL + "/validate",
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            if (response.data.statusCode !== StatusCode.AuthorizedWithAdministrator) {
                // ユーザー権限確認がNGの場合、ログイン画面へ移動する。
                NavigateToCompanyLogin(navigate);
            }
            else {
                navigatetoHome();
            }
        }).catch(function (error) {
            appInsights.trackException({ ...error, errorFunction: "MasterLayout.onAppNameClick()" });
        });
    }

    function navigatetoHome() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.HOME } });
    }

    function navigatetoProcessChart() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.PROCESS_CHART } });
    }

    function navigatetoCategory() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.CATEGORY } });
    }

    function navigatetoItem() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.ITEM } });
    }

    function navigatetoUser() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.USER } });
    }

    function navigatetoRecord() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.COMPLETEDRECORD } });
    }

    function navigatetoWorkRestore() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.WORKRESTORE } });
    }

    function navigatetoProductionPlan() {
        navigate(generatePath("/:company", {
            company: urlCompanyName
        }), { replace: true, state: { id: MasterPage.PRODUCTION_PLAN } });
    }

    function onUserNameClick() {
        setShowLogout(true);
    }

    function onLogoutContainDivClick() {
        // サインアウトコンポネントを非表示する。
        setShowLogout(false);
    }

    function onLogoutClick() {
        logoutUser();
    }

    /**
     *　ユーザーログイン画面へ移動する。
     */
    function logoutUser() {
        axios({
            method: 'GET',
            url: variables.LOGIN_URL + "/logout",
            withCredentials: true,
            headers: { 'company': urlCompanyName },
        }).then(function (response) {
            NavigateToUserLogin(navigate);
        }).catch(function (error) {
            if (error.response.status === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackException({ ...error, errorFunction: "MasterLayout.logoutUser()" });
            }
        });
    }

    return (
        <>
            <PageLoading />
            <div className="h-[47px]">
                <AppTitleBar onAppNameClick={onAppNameClick} onUserNameClick={onUserNameClick} />
            </div>
            <div className="flex flex-row">
                <div className="w-[222px] h-[1033px] bg-[#F3F2F1] px-[15px]">
                    <div>
                        <div className="border-b-2 border-[#000000] mt-[41px]">
                            <SectionTitle className="text-base" Text="工程設計" />
                        </div>
                        <div>
                            <div
                                onClick={() => handleActiveLink(MasterPage.PROCESS_CHART)}
                                className={redirectPage === MasterPage.PROCESS_CHART ? selectedCss : baseCss} >製品選択</div>
                        </div>
                    </div>
                    <div>
                        <div className="border-b-2 border-[#000000] mt-[69px]">
                            <SectionTitle className="text-base" Text="マスタ設定" />
                        </div>
                        <div>
                            <div
                                onClick={() => handleActiveLink(MasterPage.CATEGORY)}
                                className={redirectPage === MasterPage.CATEGORY ? selectedCss : baseCss}>カテゴリ</div>
                            <div
                                onClick={() => handleActiveLink(MasterPage.ITEM)}
                                className={redirectPage === MasterPage.ITEM ? selectedCss : baseCss}>製品</div>
                            <div
                                onClick={() => handleActiveLink(MasterPage.USER)}
                                className={redirectPage === MasterPage.USER ? selectedCss : baseCss}>ユーザー</div>
                        </div>
                    </div>
                    <div>
                        <div className="border-b-2 border-[#000000] mt-[69px]">
                            <SectionTitle className="text-base" Text="生産計画" />
                        </div>
                        <div onClick={() => handleActiveLink(MasterPage.PRODUCTION_PLAN)}
                            className={redirectPage === MasterPage.PRODUCTION_PLAN ? selectedCss : baseCss}>生産計画登録</div>
                    </div>
                    <div>
                        <div className="border-b-2 border-[#000000] mt-[69px]">
                            <SectionTitle className="text-base" Text="生産実績" />
                        </div>
                        <div onClick={() => handleActiveLink(MasterPage.COMPLETEDRECORD)}
                            className={redirectPage === MasterPage.COMPLETEDRECORD ? selectedCss : baseCss}>完了実績</div>
                    </div>
                    <div>
                        <div className="border-b-2 border-[#000000] mt-[69px]">
                            <SectionTitle className="text-base" Text="システム" />
                        </div>
                        <div onClick={() => handleActiveLink(MasterPage.WORKRESTORE)}
                            className={redirectPage === MasterPage.WORKRESTORE ? selectedCss : baseCss}>作業復旧</div>
                    </div>
                </div>
                <div className="w-[1698px] h-[1033px] px-[15px] bg-white">
                    {redirectPage === MasterPage.PROCESS_CHART && <ProcessChart />}
                    {redirectPage === MasterPage.CATEGORY && <Category />}
                    {redirectPage === MasterPage.ITEM && <Item />}
                    {redirectPage === MasterPage.USER && <User />}
                    {redirectPage === MasterPage.COMPLETEDRECORD && <CompletedRecord />}
                    {redirectPage === MasterPage.WORKRESTORE && <WorkRestore />}
                    {redirectPage === MasterPage.PRODUCTION_PLAN && <ProductionPlan />}
                </div>
            </div>
            {
                showLogout &&
                <Logout onContainerDivClick={onLogoutContainDivClick}
                    onLogout={onLogoutClick}
                    className="absolute top-[47px] right-[10px]"
                />
            }
        </>
    )
}
export default MasterLayout;