import { React, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import SectionTitle from "./SectionTitle";
import DropDown from "./DropDown";
import { popupcss, Sort, variables } from "../Variables";
import ConfirmBox from "./ConfirmBox";
import { urlCompanyName, NavigateToCompanyLogin, ToLocalDateTime } from "../features/util/commonFun";
import CopyButton from "./CopyButton";
import { setIsLoading as setPageLoading, getIsLoading as getIsPageLoading } from "../features/Loading/pageLoadingSlice";
import { useSelector, useDispatch } from "react-redux";
import { appInsights } from "../features/util/ApplicationInsight";
import SearchBox from "./SearchBox";
import { IconContext } from "react-icons";
import { BiInfoCircle } from "react-icons/bi";
import TableTitle from "./TableTitle";
import ErrorBox from "./ErrorBox";

const axios = require("axios");
export const emptyItemObj = {
    itemCode: -1,
    dispItemCode: "",
    itemName: "",
    tempSave: false,
    lastIssueDate: "",
    isChecked: false,
}
const sortColumns = {
    none: 0,
    dispItemCode: 1,
    itemName: 2,
    tempSave: 3,
    lastIssueDate: 4,
}
const defaultSortColumnAndSortType = {
    sortColumn: sortColumns.none,
    sortType: Sort.NONE
}
const ariTxt = "あり";
const symbolTxt = "ー";
function ProcessChartCopy(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const isPageLoading = useSelector(getIsPageLoading);
    const [categorieList, setCategorieList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(-1);
    const [itemsWithoutFilter, setItemsWithoutFilter] = useState([]);
    const [displayItemList, setDisplayItemList] = useState([]);
    const [isCopyConfirm, setIsCopyConfirm] = useState(false);
    const [isExistTempSave, setIsExistTempSave] = useState(false);
    const [dispItemCodeInput, setDispItemCodeInput] = useState("");
    const [itemNameInput, setItemNameInput] = useState("");
    const [isUnissuedChecked, setIsUnissuedChecked] = useState(false);
    const [sortColumnAndSortType, setSortColumnAndSortType] = useState(defaultSortColumnAndSortType);
    const [isOverLimitedSelectItems, setIsOverLimitedSelectItems] = useState(false);
    const selectedItemCodeList = itemsWithoutFilter.length > 0 ? itemsWithoutFilter.filter(x => x.isChecked === true).map(item => item.itemCode) : [];

    useEffect(() => {
        if (categorieList.length === 0) {
            axios({
                method: 'GET',
                url: variables.CATEGORY_URL,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                let data = response.data;
                data.sort((a, b) => a.name.localeCompare(b.name));
                setCategorieList(data);
            }).catch((error) => {
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。                       
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.useEffect()" });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (itemsWithoutFilter && itemsWithoutFilter.length > 0) {
            let filteredItems = itemsWithoutFilter.filter(function (item) {
                return (
                    item.dispItemCode.toString().toLowerCase().includes(dispItemCodeInput.toString().toLowerCase()) ||
                    item.isChecked === true
                );
            });
            filteredItems = filteredItems.filter(function (item) {
                return (
                    item.itemName.toString().toLowerCase().includes(itemNameInput.toString().toLowerCase()) ||
                    item.isChecked === true
                );
            });
            if (isUnissuedChecked === true) {
                filteredItems = filteredItems.filter(function (item) {
                    return (
                        item.lastIssueDate === symbolTxt ||
                        item.isChecked === true
                    );
                });
            }
            if (sortColumnAndSortType.sortColumn === sortColumns.dispItemCode && sortColumnAndSortType.sortType !== Sort.NONE) {
                if (sortColumnAndSortType.sortType === Sort.ASC) {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return firstItem.dispItemCode.localeCompare(secondItem.dispItemCode);
                    });
                } else {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return secondItem.dispItemCode.localeCompare(firstItem.dispItemCode);
                    });
                }
            }
            else if (sortColumnAndSortType.sortColumn === sortColumns.itemName && sortColumnAndSortType.sortType !== Sort.NONE) {
                if (sortColumnAndSortType.sortType === Sort.ASC) {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return firstItem.itemName.localeCompare(secondItem.itemName);
                    });
                } else {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return secondItem.itemName.localeCompare(firstItem.itemName);
                    });
                }
            }
            else if (sortColumnAndSortType.sortColumn === sortColumns.tempSave && sortColumnAndSortType.sortType !== Sort.NONE) {
                if (sortColumnAndSortType.sortType === Sort.ASC) {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return firstItem.tempSave.localeCompare(secondItem.tempSave);
                    });
                } else {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return secondItem.tempSave.localeCompare(firstItem.tempSave);
                    });
                }
            }
            else if (sortColumnAndSortType.sortColumn === sortColumns.lastIssueDate && sortColumnAndSortType.sortType !== Sort.NONE) {
                if (sortColumnAndSortType.sortType === Sort.ASC) {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return firstItem.lastIssueDate.localeCompare(secondItem.lastIssueDate);
                    });
                } else {
                    filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                        return secondItem.lastIssueDate.localeCompare(firstItem.lastIssueDate);
                    });
                }
            }
            setDisplayItemList(filteredItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispItemCodeInput, itemNameInput, isUnissuedChecked, sortColumnAndSortType]);

    /**
     * カテゴリドロップダウンの選択変更処理
     * @param {*} e 
     */
    function handleCategorySelectedChange(e) {
        let categoryCode = e.target.value;
        setSelectedCategory(categoryCode);
        setItemsWithoutFilter([]);
        setDisplayItemList([]);
        setDispItemCodeInput("");
        setItemNameInput("");
        setIsUnissuedChecked(false);
        if (categoryCode > -1) {
            let paramObj = { categoryCode: categoryCode };
            axios({
                method: 'GET',
                url: variables.ITEM_URL + "/get-itemlist-by-categorycode",
                params: paramObj,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                let data = response.data;
                let itmList = data.map(item => {
                    return {
                        ...emptyItemObj,
                        itemCode: item.itemCode,
                        dispItemCode: item.dispItemCode,
                        itemName: item.itemName,
                        tempSave: item.lastVersion === 0 && item.rowCount >= 1 ? ariTxt : symbolTxt,
                        lastIssueDate: (item.lastIssueDate === null || item.lastIssueDate.length === 0 || (item.lastVersion === 0 && item.rowCount === 1)) ? symbolTxt
                            : ToLocalDateTime(item.lastIssueDate),
                    };
                });
                itmList = itmList.filter((x) => x.itemCode !== props.processChartObj.itemCode).sort((a, b) => a.itemName.localeCompare(b.itemName)) ?? [];
                setItemsWithoutFilter(itmList);
                setDisplayItemList(itmList);
                setSortColumnAndSortType({
                    ...defaultSortColumnAndSortType,
                    sortColumn: sortColumns.itemName,
                    sortType: Sort.ASC
                });
            }).catch((error) => {
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                    appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.handleCategorySelectedChange()" });
                }
            });
        }
    }

    /**
     * 複製の確認ボックスの「はい」ボタンのクリックイベントハンドラ
     */
    async function handleCopyConfirmBoxYesBtnClick() {
        if (isPageLoading) {
            return;
        }
        setIsCopyConfirm(false);
        dispatch(setPageLoading(true));
        await axios({
            method: 'PUT',
            url: variables.PROCESS_CHART_URL + "/is-exist-tempsave",
            headers: { 'company': urlCompanyName },
            withCredentials: true,
            data: selectedItemCodeList,
        }).then(response => {
            // 既存の一時保存データが有る場合、確認メッセージを出す。
            const isExistTempSave = response.data;
            if (isExistTempSave === true) {
                setIsExistTempSave(true);
            } else {
                copyAndTempSave();
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                NavigateToCompanyLogin(navigate);
            } else {
                appInsights.trackTrace({ params: JSON.stringify(selectedItemCodeList) });
                appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.handleCopyConfirmBoxYesBtnClick()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * 工程設定の複製処理
     */
    async function copyAndTempSave() {
        if (isPageLoading) {
            return;
        }
        dispatch(setPageLoading(true));
        let paramObj = { motoProcessChartID: props.processChartObj.id, sakiItemCodeList: selectedItemCodeList };
        await axios({
            method: 'POST',
            url: variables.EDITOR_URL + "/copy-and-tempsave",
            data: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            let responseResult = response.data;
            if (responseResult) {
                props.onCopyBtnClick(selectedCategory, selectedItemCodeList);
            }
        }).catch((error) => {
            if (error.response.status === 401) {
                // ログイン画面へ移動する。                       
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({ ...error, errorFunction: "ProcessChartCopy.copyAndTempSave()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    function handleSearchDispItemCodeInputChange(e) {
        let inputItemCode = e.target.value;
        setDispItemCodeInput(inputItemCode);
    }

    function handleSearchItemNameInputChange(e) {
        let inputItemName = e.target.value;
        setItemNameInput(inputItemName);
    }

    function handleUnissuedCheckedCheckedChange(e) {
        setIsUnissuedChecked(!isUnissuedChecked);
    }

    function handleDisplayItemsAllCheckedChange(e) {
        let isExistChecked = itemsWithoutFilter.some(item => item.isChecked === true);
        if (displayItemList.length > 10 && isExistChecked === false) {
            setIsOverLimitedSelectItems(true);
        }
        else {
            let value = !isExistChecked;
            let tempList = [...itemsWithoutFilter];
            for (let i = 0; i < displayItemList.length; i++) {
                let idx = tempList.findIndex((item) => item.itemCode === displayItemList[i].itemCode);
                if (idx > -1) {
                    tempList[idx].isChecked = value;
                }
            }
            setItemsWithoutFilter(tempList);
        }
    }

    function handleSingleItemCheckedChange(e, itemCode) {
        if (selectedItemCodeList.length < 10 || e.target.checked === false) {
            setItemsWithoutFilter((prevState) => {
                let tmp = prevState.slice();
                let idx = tmp.findIndex((item) => item.itemCode === itemCode);
                if (idx > -1) {
                    let updateObj = { ...tmp[idx] };
                    updateObj.isChecked = e.target.checked;
                    tmp[idx] = updateObj;
                }
                return tmp;
            });
        }
        else {
            setIsOverLimitedSelectItems(true);
        }
    }

    // 製品コード、製品名、一時保存、最終発行日でソートする
    function handleSortColumn(sortCol) {
        let sortType = Sort.DESC;
        if (sortCol) {
            if (sortColumnAndSortType.sortColumn === sortCol && sortColumnAndSortType.sortType === Sort.DESC) {
                sortType = Sort.ASC;
            }
            let updateObj = {
                ...defaultSortColumnAndSortType,
                sortColumn: sortCol,
                sortType: sortType
            };
            setSortColumnAndSortType(updateObj);
        }
    }

    return (
        <>
            <div className={popupcss}>
                <div className="h-[1033px] ml-[1190px] mt-[47px] w-[730px] bg-white border-l-2 shadow-md px-[15px] pt-[15px]">
                    <div className="grid grid-cols-2">
                        <SectionTitle Text="工程設計の複製" />
                        <div className="justify-self-end">
                            <button onClick={() => { props.onClose(); }}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="flex h-[56px] mt-1">
                        <CopyButton onClick={() => { setIsCopyConfirm(true); }} isEnable={selectedCategory > -1 && selectedItemCodeList.length > 0} />
                    </div>
                    <div className="h-[2px] bg-[#C8C6C4] opacity-[.20] mx-[-15px]" />
                    <div className="bg-[#DFF6DD] w-[700px] h-[37px] mt-1">
                        <div className="inline-flex ml-[14px] pt-[8px]">
                            <IconContext.Provider value={{ color: "#000000", size: "20px", className: "self-center" }}>
                                <BiInfoCircle />
                            </IconContext.Provider>
                            <div className="w-[355px] h-[21px] text-[16px] ml-[8px] text-[#000000]">
                                一度に最大10個まで複製可能です。
                            </div>
                        </div>
                    </div>
                    <div className="h-[110px] mt-[16px]">
                        <SectionTitle Text={"複製元"} />
                        <div className=" flex mt-2">
                            <div className="font-bold text-[18px] w-[70px]">製品名</div>
                            <div className="text-[18px] w-[640px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {props.processChartObj.itemName}
                            </div>
                        </div>
                        <div className=" flex mt-2">
                            <div className="font-bold text-[18px] w-[70px]">版</div>
                            <div className="text-[18px] w-[610px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {props.processChartObj.version.toString().padStart(3, '0')}
                            </div>
                        </div>
                    </div>
                    <div className="h-[110px]">
                        <SectionTitle Text={"複製先"} />
                        <div className="items-center mb-8 mt-3">
                            <div className="w-[135px] text-[20px] mb-2">
                                <label htmlFor="cateName">カテゴリ<span className="text-[#CD0000] ml-1 font-bold">*</span></label>
                            </div>
                            <DropDown
                                itemsSource={categorieList}
                                selectedValuePath={"id"}
                                displayMemberPath={"name"}
                                onChange={(e) => handleCategorySelectedChange(e)}
                                selectedValue={selectedCategory}
                                firstOption="カテゴリを選択して下さい。"
                            />
                        </div>
                        <div className="flex h-[90px]">
                            <div className="mr-[20px]">
                                <div className=" h-[30px]">製品コード</div>
                                <SearchBox width={225}
                                    onChange={(e) => handleSearchDispItemCodeInputChange(e)}
                                    value={dispItemCodeInput}
                                />
                            </div>
                            <div className=" mr-[20px]">
                                <div className=" h-[30px]">製品名</div>
                                <SearchBox width={225}
                                    onChange={(e) => handleSearchItemNameInputChange(e)}
                                    value={itemNameInput}
                                />
                            </div>
                            <div className="flex mt-[30px]">
                                <input className="w-[21px] h-[21px] mt-1"
                                    id="onlyunissued"
                                    checked={isUnissuedChecked}
                                    type="checkbox"
                                    onChange={(e) => handleUnissuedCheckedCheckedChange(e)}
                                />
                                <label htmlFor="onlyunissued">
                                    <div className="w-[130px] ml-2 mt-[2px]">
                                        未発行のみ表示
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="w-[708px] h-[530px]">
                            <div className="relative inset-0 top-[46px] w-[685px] bg-white border-b-[2px] border-[#D8D7D5]" />
                            <div className="h-full w-full overflow-x-scroll overflow-y-scroll mt-3">
                                <table className="border-collapse">
                                    <thead>
                                        <tr>
                                            <th className="header">
                                                <div className="h-[30px] w-[40px] border-r-2">
                                                    <input key={selectedCategory} type="checkbox"
                                                        className={"w-[21px] h-[21px] flex items-center ml-2 rounded-[2px] " + (selectedItemCodeList.length > 0 && selectedItemCodeList.length < displayItemList.length ? "accent-[#F3F2F1]" : "accent-[#0073CD]")}
                                                        checked={selectedItemCodeList.length > 0}
                                                        disabled={displayItemList.length === 0}
                                                        onChange={(e) => handleDisplayItemsAllCheckedChange(e)}
                                                    />
                                                </div>
                                            </th>
                                            <th className="header">
                                                <div className="border-r-2 h-[30px] w-[145px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                                    <TableTitle horiPadding={5} Text="製品コード"
                                                        onClick={() => handleSortColumn(sortColumns.dispItemCode)}
                                                        Sort={sortColumnAndSortType.sortColumn === sortColumns.dispItemCode
                                                            ? sortColumnAndSortType.sortType
                                                            : Sort.NONE}
                                                    />
                                                </div>
                                            </th>
                                            <th className="header">
                                                <div className="border-r-2 h-[30px] w-[195px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                                    <TableTitle horiPadding={5} Text="製品名"
                                                        onClick={() => handleSortColumn(sortColumns.itemName)}
                                                        Sort={sortColumnAndSortType.sortColumn === sortColumns.itemName
                                                            ? sortColumnAndSortType.sortType
                                                            : Sort.NONE}
                                                    />
                                                </div>
                                            </th>
                                            <th className="header">
                                                <div className="border-r-2 h-[30px] w-[130px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                                    <TableTitle horiPadding={5} Text="一時保存"
                                                        onClick={() => handleSortColumn(sortColumns.tempSave)}
                                                        Sort={sortColumnAndSortType.sortColumn === sortColumns.tempSave
                                                            ? sortColumnAndSortType.sortType
                                                            : Sort.NONE}
                                                    />
                                                </div>
                                            </th>
                                            <th className="header">
                                                <div className="border-r-2 h-[30px] w-[160px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                                    <TableTitle horiPadding={5} Text="最終発行日"
                                                        onClick={() => handleSortColumn(sortColumns.lastIssueDate)}
                                                        Sort={sortColumnAndSortType.sortColumn === sortColumns.lastIssueDate
                                                            ? sortColumnAndSortType.sortType
                                                            : Sort.NONE}
                                                    />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {displayItemList.map((singleObj) => (
                                            <tr key={singleObj.itemCode} className={"h-[45px] border-y-[1px] border-[#C8C6C4]-[.56] " + variables.HOVER_CSS + (selectedItemCodeList.includes(singleObj.itemCode) ? " bg-[#D8D7D5]" : "")}>
                                                <td className="h-[30px] w-[30px]">
                                                    <input key={singleObj.itemCode} type="checkbox" className="w-[21px] h-[21px] flex items-center ml-2 accent-[#0073CD] rounded-[2px]"
                                                        checked={selectedItemCodeList.includes(singleObj.itemCode)}
                                                        onChange={(e) => handleSingleItemCheckedChange(e, singleObj.itemCode)} />
                                                </td>
                                                <td className="text-left pl-[8px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.dispItemCode}</span></td>
                                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.itemName}</span></td>
                                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.tempSave}</span></td>
                                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.lastIssueDate}</span></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isCopyConfirm && <ConfirmBox
                className="absolute right-[20px] top-[150px] w-[528px]"
                title="工程設計の複製"
                message={<p className='break-all'>
                    {`「${props.processChartObj.itemName} ${props.processChartObj.version.toString().padStart(3, '0')}版」の工程設定を`}
                    <br />
                    {"選択した製品の一時保存に複製します。"}
                    <br />
                    {"よろしいですか？"}
                </p>}
                onYesClick={() => handleCopyConfirmBoxYesBtnClick()}
                onNoClick={() => { setIsCopyConfirm(false); }}
            />}
            {isExistTempSave && <ConfirmBox
                className="absolute right-[20px] top-[150px] w-[528px]"
                title="工程設計の複製"
                message={<p className='break-all'>
                    {"複製しようとしている製品の中に、"}
                    <br />
                    {"一時保存がある製品が含まれています。"}
                    <br />
                    {"上書きしても、よろしいですか？"}
                </p>}
                onYesClick={() => { copyAndTempSave(); setIsExistTempSave(false); }}
                onNoClick={() => { setIsExistTempSave(false); }}
            />}
            {isOverLimitedSelectItems && (
                <ErrorBox
                    className="absolute right-[162px] top-[600px] w-[528px]"
                    Title="工程設計の複製"
                    Message={<p>
                        {"選択上限を超えました。"}
                        <br />
                        {"一度に最大10個を超える複製はできません。"}
                    </p>}
                    onYesClick={() => setIsOverLimitedSelectItems(false)}
                />
            )}
        </>
    )
}

export default ProcessChartCopy;