import { React } from 'react';
import LoginUserDisplay from './LoginUserDisplay';
import { useNavigate } from 'react-router-dom';
import { variables } from '../Variables';
import { NavigateToVersion } from '../features/util/commonFun';

function AppTitleBar(props) {
  let navigate = useNavigate();

  function onClickVersionBtn() {
    NavigateToVersion(navigate);
  }

  return (
    <>
      <div className={'grid grid-cols-2 items-center bg-[#0073CD] opacity-90 h-[47px]'}>
        <div className='flex text-[#FFFFFF] h-full w-fit mx-[15px] font-bold'>
          <div onClick={props.onAppNameClick} className='flex items-center w-fit h-full px-5 hover:cursor-pointer hover:bg-[#1980D1]'>
            Roland DG Assemble
          </div>
          <div className="flex items-center w-fit h-full ">
            <button
              type="button"
              className={"w-[70px] h-[28px] font-bold text-[#0073CD] text-center ml-[10px] bg-white rounded-full " + variables.HOVER_CSS}
              onClick={onClickVersionBtn}
            >{variables.VERSION}
            </button>
          </div>
        </div>
        <div className='grow flex flex-row-reverse h-full'>
          <LoginUserDisplay onClick={props.onUserNameClick} />
        </div>
      </div>
    </>
  );
}

export default AppTitleBar;
