import React, { useEffect, useRef, useState } from "react";
import { TextType, popupcss, variables } from "../../src/Variables";
import SectionTitle from "./SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import Save from "./Save";
import { BiInfoCircle } from "react-icons/bi";
import { IconContext } from "react-icons";
import DropDown from "./DropDown";
import { urlCompanyName, NavigateToCompanyLogin } from "../features/util/commonFun";
import { useNavigate } from "react-router";
import { appInsights } from "../features/util/ApplicationInsight";
import InputBox from "./InputBox";
import { useDispatch } from "react-redux";
import { setIsLoading as setPageLoading } from "../features/Loading/pageLoadingSlice";
import ConfirmBox from "./ConfirmBox";

const axios = require("axios");
let prevProcessList = [];
const regex = /^\d{0,3}(\.\d{0,2})?$/;
const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;
var hash = require('object-hash');
export function ProcessChartTimeSetting(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let processChartObj = props.processChartObj;
    const [selectedReferenceVer, setSelectedReferenceVer] = useState(-1);
    const [bindDataVersionList, setBindDataVersionList] = useState([]);
    const [currProcessList, setCurrProcessList] = useState([]);
    const [referenceProcessList, setReferenceProcessList] = useState([]);
    const [displayTooltip, setTooltip] = useState(null);
    const [isLabelTextOverWidth, setIsLabelTextOverWidth] = useState(false);
    const [isShowSaveConfirmMessage, setIsShowSaveConfirmMessage] = useState(false);
    const lblTextRef = useRef(null);

    useEffect(() => {
        if (processChartObj && processChartObj.itemCode > -1) {
            const fetchData = async () => {
                prevProcessList = await getProcessListByChartID(processChartObj.id);
                setCurrProcessList(prevProcessList);
                await getReferenceVersionList();
            }
            fetchData();
            setIsLabelTextOverWidth(measureTextOverWidth());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // 製品コードで参照するバージョンリストを取る
    async function getReferenceVersionList() {
        dispatch(setPageLoading(true));
        let paramObj = { itemCode: processChartObj.itemCode };
        await axios({
            method: 'GET',
            url: variables.PROCESS_CHART_URL + "/get-versions-by-itemcode",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            let data = response.data;
            let verList = data.filter(v => v.version !== processChartObj.version).sort((a, b) => b.version - a.version);
            setBindDataVersionList(verList.map(v => {
                return {
                    processChartId: v.processChartId,
                    version: v.version,
                    displayText: v.version.toString().padStart(3, '0')
                };
            }));
        }).catch((error) => {
            if (error.response.status === 401) {
                // ログイン画面へ移動する。                       
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: paramObj });
                appInsights.trackException({ ...error, errorFunction: "ProcessChartTimeSetting.getReferenceVersionList()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    // ChartIDで工程リストを取る
    async function getProcessListByChartID(chartID) {
        dispatch(setPageLoading(true));
        let responseData = [];
        if (chartID.length > 0) {
            let paramObj = { chartID: chartID };
            await axios({
                method: 'GET',
                url: variables.PROCESS_URL + "/get-process-list-by-chartid",
                params: paramObj,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                let data = response.data;
                data.sort((a, b) => a.dispOrder - b.dispOrder)
                responseData = data.map(item => {
                    return {
                        processChartID: item.processChartID,
                        dispProcessID: item.dispProcessID,
                        processID: item.processID,
                        processName: item.processName,
                        dispOrder: item.dispOrder,
                        notifyOfEnd: item.notifyOfEnd,
                        standardTime: item.standardTime > 0 ? item.standardTime : "",
                    };
                })
            }).catch((error) => {
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。                       
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: paramObj });
                    appInsights.trackException({ ...error, errorFunction: "ProcessChartTimeSetting.getProcessListByChartID()" });
                }
            });
        }
        dispatch(setPageLoading(false));
        return responseData;
    }

    /**
     * 参照する版を変更するイベント
     * @param {*} e 
     */
    async function handleReferenceVersionChange(e) {
        let selectedVer = Number(e.target.value);
        let selectedChartId = bindDataVersionList.find(obj => obj.version === selectedVer)?.processChartId ?? "";
        setSelectedReferenceVer(selectedVer);
        let processList = await getProcessListByChartID(selectedChartId);
        setReferenceProcessList(processList);
    }

    /**
     * 標準工数の設定を変更するイベント
     * @param {*} e 
     * @param {*} processID 
     */
    function handleInputTextChange(e, processID) {
        let inputTxt = e.target.value.trim();
        if (isExistFullWidthCharacter(inputTxt) || regex.test(inputTxt)) {
            setCurrProcessList((prevState) => {
                let tmp = prevState.slice();
                let idx = tmp.findIndex((item) => item.processID === processID);
                if (idx > -1) {
                    let updateObj = { ...tmp[idx] };
                    updateObj.standardTime = inputTxt;
                    tmp[idx] = updateObj;
                }
                return tmp;
            });
        }
    }

    /**
     * 記入するテキストが全角かをチェックする
     * @param {*} str 
     * @returns 
     */
    function isExistFullWidthCharacter(str) {
        for (let i = 0; i < str.length; i++) {
            if (fullWidthPattern.test(str[i])) {
                return true;
            }
        }
        return false;
    }

    /**
     * 標準工数テキストボックのEnterキーアップイベント
     * @param {*} e 
     * @param {*} str 
     * @param {*} processID 
     */
    function handleInputTextKeyUp(e, str, processID) {
        if (e.key === 'Enter') {
            inputTextFilter(str, processID);
        }
    }

    /**
     * 標準工数テキストをフィルタする処理
     * @param {*} str 
     * @param {*} processID 
     */
    function inputTextFilter(str, processID) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(regex)) {
                value += str[i];
            }
        }

        if (regex.test(value) === false) {
            let newStr = "";
            let currProcess = currProcessList.find(m => m.processID === processID);
            let inputTxt = currProcess.standardTime;
            const argStr = inputTxt.split(/[.]/);
            if (argStr.length === 1 || argStr.length === 2) {
                let fullWidthStartIdx = argStr[0].search(fullWidthPattern);
                if (fullWidthStartIdx > -1) {
                    let temp = argStr[0].replace(fullWidthPattern, '');
                    newStr = temp.slice(0, fullWidthStartIdx);
                    newStr += value.slice(fullWidthStartIdx, fullWidthStartIdx + (3 - temp.length));
                    newStr += temp.slice(fullWidthStartIdx, temp.length);
                    newStr += argStr.length === 2 ? '.' + argStr[1] : "";
                }
                fullWidthStartIdx = argStr.length === 2 ? argStr[1].search(fullWidthPattern) : -1;
                if (fullWidthStartIdx > -1) {
                    newStr = argStr[0] + '.';
                    let temp = argStr[1].replace(fullWidthPattern, '');
                    newStr += temp.slice(0, fullWidthStartIdx);
                    newStr += value.slice(newStr.length, newStr.length + (2 - temp.length));
                    newStr += temp.slice(fullWidthStartIdx, temp.length);
                }
            }
            value = newStr;
        }
        if (value.length > 0) {
            value = isNaN(value) ? value : Number(value) > 0 ? Number(value) : "";
        }
        setCurrProcessList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((item) => item.processID === processID);
            if (idx > -1) {
                let updateObj = { ...tmp[idx] };
                updateObj.standardTime = value;
                tmp[idx] = updateObj;
            }
            return tmp;
        });
    }

    /**
     * 製品コード、製品名ラベルのツールチップ表示処理
     * @param {*} e 
     */
    function handleLabelMouseOverToDisplayTooltip(e) {
        if (displayTooltip === null && isLabelTextOverWidth) {
            e.stopPropagation();
            e.preventDefault();
            setTooltip(
                <div style={{ position: 'absolute ', left: '1085px', top: '305px' }} onMouseLeave={() => { setTooltip(null); }}>
                    <div className={"max-w-[820px] break-all px-2 py-2 rounded-sm border-2 border-[#777777] bg-white text-[18px]"}>
                        {processChartObj?.displayItemCode}<span className="ml-4">{processChartObj?.itemName}</span>
                    </div>
                </div>
            );
        }
    }

    /**
     * 製品コード、製品名ラベルの文字幅を確認
     * @returns 
     */
    function measureTextOverWidth() {
        const lblMaxWidth = 1612;
        const textElement = lblTextRef?.current;
        if (textElement) {
            const text = textElement.textContent;
            const fontSize = getComputedStyle(textElement).fontSize;
            const tempElement = document.createElement('span');
            tempElement.style.fontSize = fontSize;
            tempElement.style.whiteSpace = 'nowrap';
            tempElement.innerText = text;
            document.body.appendChild(tempElement);
            const width = tempElement.getBoundingClientRect().width;
            document.body.removeChild(tempElement);
            return width > lblMaxWidth;
        }
        return false;
    }

    async function updateStandardTimeData() {
        dispatch(setPageLoading(true));
        let data = {
            listProcessPost: currProcessList.map(item => {
                return {
                    processChartID: item.processChartID,
                    dispProcessID: item.dispProcessID,
                    processID: item.processID,
                    processName: item.processName,
                    dispOrder: item.dispOrder,
                    notifyOfEnd: item.notifyOfEnd,
                    standardTime: Number(item.standardTime),
                };
            })
        }
        await axios({
            method: 'PUT',
            url: variables.PROCESS_URL + "/update-standardtime-by-processid",
            data: data,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            props.onClose();
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: data });
                appInsights.trackException({ ...error, errorFunction: "ProcessChartTimeSetting.updateStandardTimeData()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    function isCheckDataChanged() {
        return hash(prevProcessList) !== hash(currProcessList);
    }

    return (
        <>
            <div className={popupcss}>
                <div className="h-[1033px] ml-[1040px] mt-[47px] w-[880px] bg-white border-l-2 shadow-md px-[15px] pt-[30px]" >
                    <div className="grid grid-cols-2">
                        <SectionTitle Text="標準工数の設定" />
                        <div className="justify-self-end">
                            <button onClick={props.onClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="flex h-[56px] mt-1">
                        <Save isEnable={isCheckDataChanged()} onClick={() => { setIsShowSaveConfirmMessage(isCheckDataChanged()); }} />
                    </div>
                    <div className="h-[2px] bg-[#C8C6C4] opacity-[0.3] mx-[-15px]" />
                    <div className="bg-[#DFF6DD] w-[860px] h-[64px] ml-[-6px] mt-[6px]">
                        <div className="inline-flex ml-[19px] pt-[8px]">
                            <IconContext.Provider value={{ color: "#000000", size: "20px", className: "self-center" }}>
                                <BiInfoCircle />
                            </IconContext.Provider>
                            <div className="w-[355px] h-[21px] text-[16px] ml-[8px] text-[#000000]">
                                単位は「分」で入力してください。
                            </div>
                        </div>
                        <div className="ml-[45px] w-[513px] h-[21px] text-[16px] text-[#000000]">
                            標準工数の設定が無い場合は空欄にしてください。
                        </div>
                    </div>
                    <div ref={lblTextRef} className="font-bold text-[18px] mt-3 h-[55px] leading-[22px] w-[840px] break-all two-lines"
                        onMouseOver={(e) => handleLabelMouseOverToDisplayTooltip(e)}
                        onMouseLeave={() => { setTooltip(null); }}>
                        {processChartObj?.displayItemCode}<span className="ml-4">{processChartObj?.itemName}</span>
                    </div>
                    <div className="mt-2 ml-[433px]">
                        <DropDown
                            width={"w-[170px]"}
                            itemsSource={bindDataVersionList}
                            selectedValuePath={"version"}
                            displayMemberPath={"displayText"}
                            onChange={handleReferenceVersionChange}
                            firstOption="参照する版を選択"
                        />
                    </div>
                    <div className="flex mt-2 ml-[-2px]">
                        <div className="relative w-[415px] h-[720px] border-[1px] border-[#707070] rounded-sm">
                            <div className="absolute h-[40px] w-[405px] ml-1 p-2 border-b-2 font-bold text-[20px]">{processChartObj?.version.toString().padStart(3, '0') + "版"}</div>
                            <div className="absolute bottom-[1px] right-0 h-[675px] w-[409px] ml-1 overflow-y-scroll overflow-x-hidden">
                                {currProcessList.map((currSingleObj, idx) => (
                                    <div key={idx} className="mt-[13px] mb-2 ml-[6px]">
                                        <div>{currSingleObj.processName}</div>
                                        <InputBox
                                            className="w-[100px] h-[29px] px-1 text-right border-[1px] border-[#707070] outline-none focus:border-[#0073CD] rounded-sm"
                                            maxLength={6}
                                            value={currSingleObj.standardTime}
                                            textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                                            onChange={(e) => handleInputTextChange(e, currSingleObj.processID)}
                                            onKeyUp={(e, str) => handleInputTextKeyUp(e, str, currSingleObj.processID)}
                                            onFocusOut={(txt) => inputTextFilter(txt, currSingleObj.processID)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="relative left-[20px] w-[415px] h-[720px] border-[1px] border-[#707070] rounded-sm bg-[#F3F2F1]">
                            <div className="absolute h-[40px] w-[405px] ml-1 p-2 border-b-2 font-bold text-[20px]">{selectedReferenceVer > 0 ? selectedReferenceVer.toString().padStart(3, '0') + "版" : ""}</div>
                            <div className="absolute bottom-[1px] right-0 h-[675px] w-[409px] ml-1 overflow-y-scroll overflow-x-hidden">
                                {referenceProcessList.map((referenceSingleObj, idx) => (
                                    <div key={idx} className="mt-[13px] mb-2 ml-[6px]">
                                        <div>{referenceSingleObj.processName}</div>
                                        <input className="w-[100px] h-[29px] px-1 text-right border-[1px] border-[#707070] outline-none rounded-sm"
                                            readOnly={true}
                                            value={referenceSingleObj.standardTime}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {displayTooltip}
            </div>
            {isShowSaveConfirmMessage && <ConfirmBox
                className="absolute left-[1289px] top-[265px] w-[471px]"
                title="入力内容の登録"
                message={"登録しますか？"}
                onYesClick={() => { updateStandardTimeData(); setIsShowSaveConfirmMessage(false); }}
                onNoClick={() => { setIsShowSaveConfirmMessage(false); }}
            />}
        </>
    )
}