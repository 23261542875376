import React from "react";
import { BiEdit } from "react-icons/bi";
import { FaCopy } from "react-icons/fa";
import { IconContext } from "react-icons";
import { variables } from "../Variables";
import { IoMdStopwatch } from "react-icons/io";
import { LiaTrashAlt } from "react-icons/lia";

function ProcessChartEditMenu(props) {
    let layoutSizeCss = "w-[142px] h-[153px]";

    return (
        <div className={props.className || ("drop-shadow-[0_6px_6px_rgba(96,94,92,1)] border-2 border-[#C8C6C4] bg-white mt-[1px] " + layoutSizeCss)} >
            <div onClick={props.onEditBtnClick}
                className={"flex items-center w-full h-[50px] cursor-pointer " + variables.HOVER_CSS}>
                <IconContext.Provider
                    value={{ color: "#0073CD", size: "16px", className: "self-center ml-3" }}>
                    <BiEdit />
                </IconContext.Provider>
                <div className="text-[#000000] ml-[6px]">
                    編集
                </div>
            </div>
            <div onClick={props.onCopyBtnClick}
                className={"flex items-center w-full h-[50px] cursor-pointer " + variables.HOVER_CSS}>
                <IconContext.Provider
                    value={{ color: "#0073CD", size: "16px", className: "self-center ml-3" }}>
                    <FaCopy />
                </IconContext.Provider>
                <div className="text-[#000000] ml-[6px]">
                    複製
                </div>
            </div>
            {props.isShowTimeSettingBtn &&
                <div onClick={props.onTimeSettingBtnClick}
                    className={"flex items-center w-full h-[50px] cursor-pointer " + variables.HOVER_CSS}>
                    <IconContext.Provider
                        value={{ color: "#0073CD", size: "16px", className: "self-center ml-3" }}>
                        <IoMdStopwatch />
                    </IconContext.Provider>
                    <div className="text-[#000000] ml-[6px]">
                        標準工数設定
                    </div>
                </div>
            }
            {props.isShowTempSaveDeleteBtn &&
                <div onClick={props.onDeleteBtnClick}
                    className={"flex items-center w-full h-[50px] cursor-pointer " + variables.HOVER_CSS}>
                    <IconContext.Provider
                        value={{ color: "#0073CD", size: "18px", className: "self-center ml-[11px]" }}>
                        <LiaTrashAlt />
                    </IconContext.Provider>
                    <div className="text-[#000000] ml-[5px]">
                        削除
                    </div>
                </div>
            }
        </div>
    );
}

ProcessChartEditMenu.defaultProps = {
    isShowTimeSettingBtn: false,
    isShowTempSaveDeleteBtn: false,
    onEditBtnClick: () => { void (0) },
    onCopyBtnClick: () => { void (0) },
    onTimeSettingBtnClick: () => { void (0) },
}
export default ProcessChartEditMenu;