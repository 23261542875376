import React from "react";
import SectionTitle from "../../components/SectionTitle";
import { variables, CompanyLoginPath } from "../../Variables";
import Collapsible from "../../components/Collapsible";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import ProcessChartEditMenu from "../../components/ProcessChartEditMenu";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { ToLocalDateTime, urlCompanyName } from "../util/commonFun";
import ProcessChartCopy from "../../components/ProcessChartCopy";
import ConfirmBox from "../../components/ConfirmBox";
import { appInsights } from "../util/ApplicationInsight";
import SearchBox from "../../components/SearchBox";
import { ProcessChartTimeSetting } from "../../components/ProcessChartTimeSetting";

const axios = require('axios');
const processChartID = uuidv4();
let newKey = uuidv4();
// 一時保存がある場合は、「一時保存＋最新の1版＋さらに表示」の3件
// 一時保存がない場合は、「最新の2版＋さらに表示」の3件
const initMaxDisplayRows = 3;
// 「さらに表示」を押すと5件表示する
const showMoreRowsCnt = 5;
var hash = require('object-hash');

const initialState = {
    categories: [],
    items: [],
    processChartDatas: [],
    editBtn: { id: -1, btn: null },
    isRedirectToCompanyLogin: false,
    copyLayout: null,
    isNavigateToEditor: false,
    processChartObj: null,
    dialogBox: null,
    currFirstItemCodeCopied: -1,
    expandCateCodeList: [],
    expandItemCodeList: [],
    bindDataCategories: [],
    bindDataItems: [],
    categoryFilterText: "",
    itemCodeFilterText: "",
    itemNameFilterText: "",
    timeSettingLayout: null,
    isDisplayTempSaveOnly: false,
};

class ProcessChart extends React.Component {
    constructor(props) {
        super(props);
        let categoryCodeList = sessionStorage.getItem('categoryCodeList') ? sessionStorage.getItem('categoryCodeList').split(',').map(Number) : [];
        let itemCodeList = sessionStorage.getItem('itemCodeList') ? sessionStorage.getItem('itemCodeList').split(',').map(Number) : [];
        initialState.expandCateCodeList = categoryCodeList;
        initialState.expandItemCodeList = itemCodeList;
        initialState.categoryFilterText = sessionStorage.getItem('categoryFilterText') ? sessionStorage.getItem('categoryFilterText') : "";
        initialState.itemCodeFilterText = sessionStorage.getItem('itemCodeFilterText') ? sessionStorage.getItem('itemCodeFilterText') : "";
        initialState.itemNameFilterText = sessionStorage.getItem('itemNameFilterText') ? sessionStorage.getItem('itemNameFilterText') : "";
        initialState.isDisplayTempSaveOnly = sessionStorage.getItem('isDisplayTempSaveOnly') ? JSON.parse(sessionStorage.getItem('isDisplayTempSaveOnly')) : false;
        this.state = initialState;
        this.refreshList = this.refreshList.bind(this);
        this.getCategoryData = this.getCategoryData.bind(this);
        this.getitemdata = this.getitemdata.bind(this);
        this.clearEditButton = this.clearEditButton.bind(this);
        this.getProcessChartData = this.getProcessChartData.bind(this);
        this.handleThreeDotsClick = this.handleThreeDotsClick.bind(this);
        this.redirect = this.redirect.bind(this);
        this.handleLinkColOnClick = this.handleLinkColOnClick.bind(this);
        this.handleCategoryCollapsibleChange = this.handleCategoryCollapsibleChange.bind(this);
        this.handleItemCollapsibleChange = this.handleItemCollapsibleChange.bind(this);
        this.handleCopyBtnClick = this.handleCopyBtnClick.bind(this);
        this.handleEditBtnClick = this.handleEditBtnClick.bind(this);
        this.handleTimeSettingBtnClick = this.handleTimeSettingBtnClick.bind(this);
        this.handleTempSaveDeleteBtnClick = this.handleTempSaveDeleteBtnClick.bind(this);
        this.handleCopyCompleteAndRefresh = this.handleCopyCompleteAndRefresh.bind(this);
        this.deleteTempSaveByChartID = this.deleteTempSaveByChartID.bind(this);
        this.handleSearchCategoryInputChange = this.handleSearchCategoryInputChange.bind(this);
        this.handleSearchItemCodeInputChange = this.handleSearchItemCodeInputChange.bind(this);
        this.handleSearchItemNameInputChange = this.handleSearchItemNameInputChange.bind(this);
        this.handleDisplayTempSaveOnlyCheckedChange = this.handleDisplayTempSaveOnlyCheckedChange.bind(this);
        this.handleShowMore = this.handleShowMore.bind(this);
    }

    componentDidMount() {
        this.refreshList();
    }

    refreshList() {
        this.getProcessChartData();
        this.getitemdata();
        this.getCategoryData();
    }

    redirect() {
        this.setState({
            isRedirectToCompanyLogin: true
        });
    }

    //カテゴリのDataを取得する。
    getCategoryData() {
        axios({
            method: 'GET',
            url: variables.CATEGORY_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                let data = response.data;
                data.sort((a, b) => a.name.localeCompare(b.name));
                this.setState((prStat) => ({
                    ...prStat,
                    categories: data,
                }));
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    this.redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChart.getCategoryData()" });
                }
            });
    }

    // 編集ボタンをクリアする。
    clearEditButton() {
        this.setState({
            editBtn: { id: -1, btn: null }
        });
    }

    //製品のDataを取得する。
    getitemdata() {
        axios({
            method: 'GET',
            url: variables.ITEM_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                let data = response.data.filter(itm => itm.isEnd === false);
                let itemList = data.map(itemObj => ({ ...itemObj, maxDisplayChartRows: initMaxDisplayRows, isShowMoreIncluded: true }));
                this.setState((prevState) => ({
                    ...prevState,
                    items: itemList,
                }));
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    this.redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChart.getitemdata()" });
                }
            });
    }

    //Process Chart Dataを取得する。
    getProcessChartData() {
        axios({
            method: 'GET',
            url: variables.PROCESS_CHART_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                let data = response.data;
                this.setState((prState) => ({
                    ...prState,
                    processChartDatas: data,
                }
                ));
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    this.redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProcessChart.getProcessChartData()" });
                }
            });
    }

    // 「…」を押す時のイベントハンドラ
    handleThreeDotsClick(processChartId, itemName, itemCode, chartVersion, displayItemCode, e) {
        const positionX = e.pageX + e.currentTarget.clientWidth + 10;
        const positionY = e.pageY - (e.currentTarget.clientHeight / 2);
        this.setState({
            processChartObj: { id: processChartId, itemName: itemName, itemCode: itemCode, version: chartVersion, displayItemCode: displayItemCode },
            editBtn: {
                id: processChartId,
                btn: (
                    <div style={{ position: 'absolute', left: positionX + 'px', top: positionY + 'px' }}>
                        <ProcessChartEditMenu
                            isShowTimeSettingBtn={chartVersion > 0}
                            isShowTempSaveDeleteBtn={chartVersion === 0}
                            onEditBtnClick={() => this.handleEditBtnClick()}
                            onCopyBtnClick={() => this.handleCopyBtnClick()}
                            onTimeSettingBtnClick={() => this.handleTimeSettingBtnClick()}
                            onDeleteBtnClick={() => this.handleTempSaveDeleteBtnClick(processChartId)}
                        />
                    </div>
                ),
            },
            currFirstItemCodeCopied: -1,
        });
        e.stopPropagation();
    }

    // エディタ画面へ移動するカラムのクリックイベント
    handleLinkColOnClick(processChartObj, e) {
        e.stopPropagation();
        this.setState({ isNavigateToEditor: true, processChartObj: processChartObj });
    }

    // エディタ画面へ移動する
    handleEditBtnClick() {
        this.setState({ isNavigateToEditor: true });
    }

    // 複製ボタンをクリアする
    handleCopyBtnClick() {
        this.setState({
            copyLayout: (
                <ProcessChartCopy key={uuidv4()}
                    processChartObj={this.state.processChartObj}
                    onCopyBtnClick={(cateCode, itemCode) => this.handleCopyCompleteAndRefresh(cateCode, itemCode)}
                    onClose={() => { this.setState({ copyLayout: null }); }}
                />
            ),
        });
    }

    // 複製処理が成功した上で、リフレッシュする処理
    handleCopyCompleteAndRefresh(cateCode, itemCodeList) {
        let cateList = [...this.state.expandCateCodeList];
        if (cateList.includes(Number(cateCode)) === false) {
            cateList.push(Number(cateCode));
        }
        let itemList = [...this.state.expandItemCodeList];
        for (let i = 0; i < itemCodeList.length; i++) {
            if (itemList.includes(Number(itemCodeList[i])) === false) {
                itemList.push(Number(itemCodeList[i]));
            }
        }
        this.setState({
            copyLayout: null,
            currFirstItemCodeCopied: itemCodeList.length > 0 ? itemCodeList[0] : -1,
            expandCateCodeList: cateList,
            expandItemCodeList: itemList,
        });
        this.refreshList();
        newKey = uuidv4();
        sessionStorage.setItem('categoryCodeList', cateList);
        sessionStorage.setItem('itemCodeList', itemList);
    }

    handleSearchCategoryInputChange(e) {
        this.setState({
            categoryFilterText: e.target.value,
        });
        sessionStorage.setItem('categoryFilterText', e.target.value);
    }

    handleSearchItemCodeInputChange(e) {
        this.setState({
            itemCodeFilterText: e.target.value,
        });
        sessionStorage.setItem('itemCodeFilterText', e.target.value);
    }

    handleSearchItemNameInputChange(e) {
        this.setState({
            itemNameFilterText: e.target.value,
        });
        sessionStorage.setItem('itemNameFilterText', e.target.value);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.itemCodeFilterText !== prevState.itemCodeFilterText ||
            this.state.itemNameFilterText !== prevState.itemNameFilterText ||
            this.state.categoryFilterText !== prevState.categoryFilterText ||
            this.state.isDisplayTempSaveOnly !== prevState.isDisplayTempSaveOnly ||
            hash(this.state.processChartDatas) !== hash(prevState.processChartDatas) ||
            hash(this.state.items) !== hash(prevState.items) ||
            hash(this.state.categories) !== hash(prevState.categories)) {
            let bindDataItems = this.state.items;
            let bindDataCategories = this.state.categories;

            if (this.state.itemCodeFilterText.length > 0) {
                // 製品コードテキストボックスの入力テキストでフィルタリングする
                bindDataItems = bindDataItems.filter(item => item.displayItemCode.toString().toLowerCase().includes(this.state.itemCodeFilterText.toString().toLowerCase()));
                // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
            }

            if (this.state.itemNameFilterText.length > 0) {
                // 製品名テキストボックスの入力テキストでフィルタリングする
                bindDataItems = bindDataItems.filter(item => item.itemName.toString().toLowerCase().includes(this.state.itemNameFilterText.toString().toLowerCase()));
                // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
            }

            if (this.state.categoryFilterText.length > 0) {
                // カテゴリーテキストボックスの入力テキストでフィルタリングする             
                bindDataCategories = bindDataCategories.filter(cate => cate.name.toString().toLowerCase().includes(this.state.categoryFilterText.toString().toLowerCase()));
            }

            if (this.state.isDisplayTempSaveOnly) {
                // 一時保存のみ製品コードリストを取る   
                let tempSaveOnlyItemCodeList = this.state.processChartDatas.filter(x => x.version === 0).map(y => y.itemCode);
                // 一時保存のみ製品コードリストでフィルタリングする
                bindDataItems = bindDataItems.filter(item => tempSaveOnlyItemCodeList.includes(item.id));
                // 現在のフィルタリングした製品一覧によってカテゴリーリストを変更する
                bindDataCategories = bindDataCategories.filter(cate => bindDataItems.some(item => item.categoryId === cate.id));
                let filteredCateCodeList = bindDataCategories.map(cate => cate.id);
                let filteredItemCodeList = bindDataItems.map(item => item.id);
                this.setState({
                    expandCateCodeList: filteredCateCodeList,
                    expandItemCodeList: filteredItemCodeList,
                });
                newKey = uuidv4();
                sessionStorage.setItem('categoryCodeList', filteredCateCodeList);
                sessionStorage.setItem('itemCodeList', filteredItemCodeList);
            }
            this.setState({
                bindDataCategories: bindDataCategories,
                bindDataItems: bindDataItems,
            });
        }
    }

    // 標準工数の設定ボタンをクリック操作
    handleTimeSettingBtnClick() {
        this.setState({
            timeSettingLayout: (
                <ProcessChartTimeSetting key={uuidv4()}
                    processChartObj={this.state.processChartObj}
                    onClose={() => { this.setState({ timeSettingLayout: null }); }}
                />
            ),
            dialogBox: null,
            copyLayout: null,
        });
    }

    // 一時保存を削除の確認メッセージ表示
    handleTempSaveDeleteBtnClick(processChartId) {
        this.setState({
            dialogBox: (
                <ConfirmBox
                    className="absolute left-[710px] top-[410px] w-[471px]"
                    title="一時保存の削除" message={"選択した一時保存を削除しますか？"}
                    onYesClick={() => { this.deleteTempSaveByChartID(processChartId) }}
                    onNoClick={() => { this.setState({ dialogBox: null }); }}
                />
            ),
        });
    }

    // 一時保存を削除処理
    deleteTempSaveByChartID(processChartId) {
        let paramObj = { processChartID: processChartId };
        axios({
            method: 'GET',
            url: variables.EDITOR_URL + "/delete-tempsave",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            let responseData = response.data;
            if (responseData === true) {
                this.refreshList();
            }
        }).catch((error) => {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                this.redirect();
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({ ...error, errorFunction: "ProcessChart.deleteTempSaveByChartID()" });
            }
        });
        this.setState({ dialogBox: null });
    }

    // カテゴリーの展開処理
    handleCategoryCollapsibleChange(value, categoryId) {
        let cateList = [...this.state.expandCateCodeList];
        let itemList = [...this.state.expandItemCodeList];
        if (value) {
            cateList.push(categoryId);
        }
        else {
            const delIndex = cateList.findIndex((id) => id === categoryId);
            cateList.splice(delIndex, 1);
            // 現在のカテゴリに関連する製品を展開製品リストから削除します
            if (itemList.length > 0) {
                let tempIdList = this.state.bindDataItems.filter(x => x.categoryId === categoryId).map(y => y.id);
                itemList = itemList.filter((id) => tempIdList.includes(id) === false);
            }
        }
        this.setState({
            expandCateCodeList: cateList,
            expandItemCodeList: itemList,
            currFirstItemCodeCopied: -1,
        });
        sessionStorage.setItem('categoryCodeList', cateList);
        sessionStorage.setItem('itemCodeList', itemList);
    }

    // 製品の展開処理
    handleItemCollapsibleChange(value, itemCode) {
        let expandItemList = [...this.state.expandItemCodeList];
        if (value) {
            expandItemList.push(itemCode);
            let motoItemList = [...this.state.items];
            let idx = motoItemList.findIndex(x => x.id === itemCode);
            if (idx > -1) {
                // 展開する場合、現在の製品の表示データを初期表示データに戻します。
                let currItemObj = motoItemList[idx];
                motoItemList[idx] = { ...currItemObj, maxDisplayChartRows: initMaxDisplayRows, isShowMoreIncluded: true };
                this.setState({
                    items: motoItemList,
                });
            }
        }
        else {
            const delIndex = expandItemList.findIndex((id) => id === itemCode);
            expandItemList.splice(delIndex, 1);
        }
        this.setState({
            expandItemCodeList: expandItemList,
            currFirstItemCodeCopied: -1,
        });
        sessionStorage.setItem('itemCodeList', expandItemList);
    }

    // 一時保存のみ表示チェックボックスの操作
    handleDisplayTempSaveOnlyCheckedChange(e) {
        this.setState({
            isDisplayTempSaveOnly: e.target.checked,
        });
        sessionStorage.setItem('isDisplayTempSaveOnly', e.target.checked);
    }

    /**
     * 「さらに表示」ボタンの操作
     * 「さらに表示」を押すと5件表示する
     * @param {*} itemCode 
     * @param {*} e 
     */
    handleShowMore(itemCode, e) {
        e.stopPropagation();
        let motoItemList = [...this.state.items];
        let idx = motoItemList.findIndex(x => x.id === itemCode);
        if (idx > -1) {
            let currItemObj = motoItemList[idx];
            // 現在表示されているチャートデータ行の数を取る
            let currDisplayChartRows = currItemObj.maxDisplayChartRows;
            // 現在の製品の元データカウント       
            let motoChartListCnt = this.state.processChartDatas.filter(x => x.itemCode === itemCode).length;
            // 表示する次の合計行を取る
            let tempShowTotalRowsCnt = currDisplayChartRows + showMoreRowsCnt;
            let showTotalRowsCnt = tempShowTotalRowsCnt > motoChartListCnt ? motoChartListCnt : tempShowTotalRowsCnt;
            // 「さらに表示」行があるかどうかを示すフラグ
            let isShowMoreIncluded = tempShowTotalRowsCnt > motoChartListCnt ? false : true;
            // 現在の製品の表示するチャートデータ行数を変更する
            motoItemList[idx] = { ...currItemObj, maxDisplayChartRows: showTotalRowsCnt, isShowMoreIncluded: isShowMoreIncluded };
            this.setState({
                items: motoItemList,
            });
        }
    }

    // スクロールのインデックスを取得する
    getDesiredScrollIndex = (cateIdx, itmIdx, targetCateCode) => {
        let templist1 = this.state.bindDataItems.filter(item => this.state.bindDataCategories.slice(0, cateIdx).filter(cate => this.state.expandCateCodeList.includes(cate.id)).some(cate => cate.id === item.categoryId)).map(x => x.id);
        let templist2 = this.state.bindDataItems.filter(item => item.categoryId === targetCateCode).sort((a, b) => a.itemName.localeCompare(b.itemName)).slice(0, itmIdx).map(x => x.id);
        let itemList = templist1.concat(templist2);
        let unexpandedItemCnt = itemList.filter(itemCode => this.state.expandItemCodeList.includes(itemCode) === false).length;
        let expandedItemCnt = itemList.filter(x => this.state.expandItemCodeList.includes(x)).length;
        itemList.filter(x => this.state.expandItemCodeList.includes(x)).forEach(itemCode => {
            let displayRows = this.state.processChartDatas.filter(x => x.itemCode === itemCode).length;
            expandedItemCnt += displayRows > initMaxDisplayRows ? initMaxDisplayRows : displayRows;
        });
        return cateIdx + unexpandedItemCnt + expandedItemCnt;
    }

    render() {
        return (
            <div onClick={this.clearEditButton}>
                {this.state.isRedirectToCompanyLogin && <><Navigate to={generatePath(CompanyLoginPath, {
                    company: urlCompanyName
                })} /></>}
                {this.state.isNavigateToEditor && <><Navigate to={generatePath("/:company/editor", {
                    company: urlCompanyName
                })} state={this.state.processChartObj} /></>}
                <div className="flex flex-row">
                    <div>
                        <div className="my-[11px] h-[21px]">工程設計</div>
                        <SectionTitle Text="製品選択" />
                        <div className="flex mt-5 h-[48px]">
                            <div className="h-[32px] mr-[15px]">
                                <label>カテゴリ</label>
                                <SearchBox width={224}
                                    onChange={(e) => this.handleSearchCategoryInputChange(e)}
                                    value={this.state.categoryFilterText}
                                />
                            </div>
                            <div className="h-[32px] mr-[15px]">
                                <label>製品コード</label>
                                <SearchBox width={224}
                                    onChange={(e) => this.handleSearchItemCodeInputChange(e)}
                                    value={this.state.itemCodeFilterText}
                                />
                            </div>
                            <div className="h-[32px] mr-[15px]">
                                <label>製品名</label>
                                <SearchBox width={224}
                                    onChange={(e) => this.handleSearchItemNameInputChange(e)}
                                    value={this.state.itemNameFilterText}
                                />
                            </div>
                            <div className="flex mt-[30px]">
                                <input className="w-[21px] h-[21px]"
                                    id="tempSaveOnly"
                                    checked={this.state.isDisplayTempSaveOnly}
                                    type="checkbox"
                                    onChange={(e) => this.handleDisplayTempSaveOnlyCheckedChange(e)}
                                />
                                <label htmlFor="tempSaveOnly">
                                    <div className="w-[130px] ml-2">
                                        一時保存のみ表示
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] mt-[20px]" />
                        <div className="w-[1683px] h-[875px] overflow-y-scroll" id={"scrollDiv"}>
                            {this.state.bindDataCategories.sort((a, b) => a.name.localeCompare(b.name)).map((category, cateIdx) => (
                                <div className="w-fit hover:cursor-pointer" key={category.id}>
                                    <Collapsible margin="ml-0" type="categoryName" key={newKey}
                                        isOpen={this.state.expandCateCodeList.includes(Number(category.id))}
                                        onChange={(value) => this.handleCategoryCollapsibleChange(value, category.id)}
                                        title={
                                            <>
                                                {category.name} ({this.state.bindDataItems.filter(item => item.categoryId === (category.id)).length})
                                            </>
                                        }>
                                        {this.state.bindDataItems
                                            .filter(item => item.categoryId === (category.id)).sort((a, b) => a.itemName.localeCompare(b.itemName))
                                            .map((filterItem, itmIdx) => (
                                                <div className="w-fit hover:cursor-pointer" key={filterItem.id}>
                                                    <Collapsible margin="ml-[42px]" type="itemName"
                                                        isOpen={this.state.expandItemCodeList.includes(filterItem.id)}
                                                        desiredScrollIndex={filterItem.id === Number(this.state.currFirstItemCodeCopied) ? this.getDesiredScrollIndex(cateIdx, itmIdx, category.id) : -1}
                                                        onChange={(value) => this.handleItemCollapsibleChange(value, filterItem.id)}
                                                        title={
                                                            <div>
                                                                {filterItem.displayItemCode}<span className="ml-4 mr-1">{filterItem.itemName}</span>({this.state.processChartDatas.filter(processChartData => processChartData.itemCode === filterItem.id).length})
                                                            </div>
                                                        }>
                                                        {this.state.processChartDatas
                                                            .filter(processChartData => processChartData.itemCode === filterItem.id).length === 0 ?
                                                            <div>
                                                                <table className="border-collpase ml-[140px] border-b-2 mt-[-2px] w-[730px]">
                                                                    <tbody>
                                                                        <tr key={processChartID}
                                                                            className={"w-[730px] h-[53px] border-b-2 border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                            <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline">
                                                                                <div className="w-fit hover:cursor-pointer" onClick={(e) => this.handleLinkColOnClick({ id: uuidv4(), itemName: filterItem.itemName, itemCode: filterItem.id, version: -1, itemManagementType: filterItem.managementType }, e)}>
                                                                                    新規作成
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div> :
                                                            this.state.processChartDatas
                                                                .filter(processChartData => processChartData.itemCode === filterItem.id)
                                                                .sort((a, b) => a.updateDate < b.updateDate ? 1 : -1)
                                                                .slice(0, filterItem.maxDisplayChartRows)
                                                                .map((filteredData, idx) => (
                                                                    <div key={filteredData.processChartId} >
                                                                        <table className="border-collpase ml-[140px] mt-[-2px] w-[730px]">
                                                                            <tbody>
                                                                                {((idx + 1) < filterItem.maxDisplayChartRows) || ((idx + 1) === filterItem.maxDisplayChartRows && filterItem.isShowMoreIncluded === false) ?
                                                                                    <tr key={filteredData.processChartId}
                                                                                        className={"w-[730px] h-[53px] border-b-2 border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                                        <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline" onClick={(e) => this.handleLinkColOnClick({ id: filteredData.processChartId, itemName: filteredData.itemName, itemCode: filteredData.itemCode, version: filteredData.version, itemManagementType: filterItem.managementType }, e)}>
                                                                                            {filteredData.version === 0 ? "一時保存" : filteredData.version.toString().padStart(3, '0') + "版"}
                                                                                        </td>
                                                                                        <td className="w-[300px] h-[53px] text-left  text-black ml-[616px]">
                                                                                            {ToLocalDateTime(filteredData.updateDate)}
                                                                                        </td>
                                                                                        <td className="w-[157px] max-w-[157px] h-[53px] text-left pl-[20px] text-black overflow-hidden text-ellipsis whitespace-nowrap">
                                                                                            {filteredData.userName}
                                                                                        </td>
                                                                                        <td>
                                                                                            <div onClick={(e) => this.handleThreeDotsClick(filteredData.processChartId, filteredData.itemName, filteredData.itemCode, filteredData.version, filterItem.displayItemCode, e)}
                                                                                                className="h-[53px] mx-[15px] flex items-center cursor-pointer text-black">
                                                                                                <IconContext.Provider value={{ color: "Black", size: "20px" }} >
                                                                                                    <BsThreeDots />
                                                                                                </IconContext.Provider>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                    :
                                                                                    <tr key={filteredData.processChartId}
                                                                                        className={"w-[730px] h-[53px] border-b-2 border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS}>
                                                                                        <td className="w-[247px] h-[53px] text-left pl-[20px] font-bold hover:underline"
                                                                                            onClick={(e) => this.handleShowMore(filteredData.itemCode, e)}>
                                                                                            さらに表示
                                                                                        </td>
                                                                                    </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                ))
                                                        }
                                                    </Collapsible>
                                                </div>
                                            ))
                                        }
                                    </Collapsible>
                                </div>
                            ))}
                        </div>
                    </div>
                    {this.state.copyLayout}
                    {this.state.timeSettingLayout}
                </div >
                {this.state.editBtn.btn}
                {this.state.dialogBox}
            </div >
        );
    }
}

export default ProcessChart;