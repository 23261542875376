import React from 'react';
import { IconContext } from 'react-icons';
import { LiaTrashAlt } from "react-icons/lia";
import { variables } from '../Variables';

class DeleteButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick() {
    if (this.props.isEnable) {
      this.props.onClick();
    }
  }

  render() {
    let iconColor = '#0073CD';
    let textColor = '';
    let hoverbgColor = variables.HOVER_CSS;
    if (!this.props.isEnable) {
      iconColor = '#A19F9D';
      textColor = 'text-[#A19F9D]';
      hoverbgColor = '';
    }
    return (
      <div
        onClick={this.handleDeleteClick}
        className={this.props.className || 'w-[70px] h-[48px]'}
      >
        <div
          className={
            'flex items-center justify-center w-full h-full hover:cursor-pointer ' +
            hoverbgColor
          }
        >
          <IconContext.Provider value={{ color: iconColor, size: '18px' }}>
            <LiaTrashAlt />
          </IconContext.Provider>
          <div className={'h-fit font-bold ' + textColor}>
            {this.props.Text}
          </div>
        </div>
      </div>
    );
  }
}
export default DeleteButton;
